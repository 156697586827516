<!-- homepage header -->
<a class="skip-to-content-link sr-only flex bg-white justify-center focus:not-sr-only" href="#main-content">Skip to main content</a>
@if (router.url === '/') {
  @if ((tripFinderHeader.state | async) !== 'show' && router.url === '/') {
    <div class="hidden lg:block bg-primary-100 shadow-xl">
      @if (showBanner) {
      <app-banner></app-banner>
      }
      <div class="h-20 flex justify-between items-center width-container px-4">
        <a routerLink="/" aria-label="home" class="block">
          <img height="32" width="250" src="{{ logoLg | imgix:'fit=crop':'h=72':'q=100' }}" [alt]="siteID + ' logo'"
            class="w-auto max-h-8 max-w-[250px] lg:max-h-9 lg:max-w-[300px]">
        </a>
        @if (logoSm) {
          <img width="105" height="36" src="{{ logoSm | imgix:'fit=crop':'h=72':'q=100' }}" [alt]="siteID + ' logo'" class="w-auto max-h-9 max-w-[105px]">
        }
      </div>
      <div class="py-2 bg-off-white border-b-2 border-light-gray">
        <div class="width-container px-4 flex justify-end">
          <app-navigation [alt]="true"></app-navigation>
        </div>
      </div>
    </div>
  } @else {
    <div [class]="showBanner ? 'h-[174px] xl:h-[178px]' : 'h-[142px] xl:h-[146px]'" class="hidden lg:block bg-primary-300"></div>
  }
}
<!-- standard header (used as a fly-in for homepage) -->
<div
  [class]="{'fly-in-nav': router.url === '/', 'show': (tripFinderHeader.state | async) === 'show' && router.url === '/'}"
  class="bg-primary-100  shadow-xl">
  @if (showBanner) {
    <app-banner></app-banner>
    }
  <div class="relative z-20 border-b-2 border-transparent lg:border-light-gray">
    <div class="h-20 flex justify-between items-center width-container px-0 sm:px-4">
      <div class="lg:hidden w-1/6">
        <a href="tel:+{{tel}}" class="p-4">
          <icon-phone class="text-white" [width]="15"></icon-phone>
        </a>
      </div>
      <a routerLink="/" aria-label="home" class="block w-4/6 lg:w-auto flex justify-center lg:justify-start">
        <img height="32" width="250" src="{{ logoLg | imgix:'fit=crop':'h=72':'q=100' }}" [alt]="siteID + ' logo'"
          class="w-auto max-h-8 max-w-[250px] lg:max-h-9 lg:max-w-[300px] px-6 sm:px-0">
      </a>
      <div class="w-1/6 lg:w-auto flex justify-end lg:ml-7 lg:shrink-0 lg:basis-auto">
        <app-navigation></app-navigation>
      </div>
    </div>
  </div>
  @if (showActionBar$ | async; as showActionBar) {
    <action-bar></action-bar>
  }  
</div>