import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {Params, Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, shareReplay, takeUntil} from 'rxjs/operators';
import {OverlayService, SiteIdService} from '@app/services';
import {Site} from '@app/models';
import {
  DateFacet,
  DestinationFacet,
  FacetConfig,
  FacetField,
  StandardFacet,
} from '@app/models/search-new.model';
import {FilterIcon, PlusIcon, SearchIcon, Ship2Icon} from '@app/icons';
import {FacetDateAccordionComponent} from '@app/facets/facet-date/facet-date-accordion/facet-date-accordion.component';
import {FacetStandardAccordionComponent} from '@app/facets/facet-standard/facet-standard-accordion/facet-standard-accordion.component';
import {FacetDateWideComponent} from '@app/facets/facet-date/facet-date-wide/facet-date-wide.component';
import {SearchService} from '@app/services/search.service';
import {FacetStandardWideComponent} from '@app/facets/facet-standard/facet-standard-wide/facet-standard-wide.component';
import {configs2} from '@app/search/search.config';
import {FacetDestinationAccordionComponent} from '@app/facets/facet-destination/facet-destination-accordion/facet-destination-accordion.component';
import {FacetDestinationWideComponent} from '@app/facets/facet-destination/facet-destination-wide/facet-destination-wide.component';
import {SearchBarService2} from '@app/services/search-bar-service-2.service';
import {SearchDriver, defaultRequestState} from '@app/services/search-driver';
import {facetBucketMap} from '../search/facet-bucket-map';

@Component({
  standalone: true,
  selector: 'search-bar-2',
  imports: [
    AsyncPipe,
    SearchIcon,
    FilterIcon,
    Ship2Icon,
    PlusIcon,
    FacetDateAccordionComponent,
    FacetStandardAccordionComponent,
    FacetDateWideComponent,
    FacetStandardWideComponent,
    FacetDestinationAccordionComponent,
    FacetDestinationWideComponent,
  ],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
})
export class SearchBar2Component implements OnInit, OnDestroy {
  readonly Site = Site;
  siteID;
  lengthBuckets = facetBucketMap['duration'];
  priceBuckets = facetBucketMap['min_price'];
  searchDriver: SearchDriver;
  searchQueryParams: Params = {};
  facetConfig: FacetConfig;
  facetDataArr: {
    config: StandardFacet | DestinationFacet | DateFacet;
    nextFacet: {label: string; field: string} | null;
    options: Observable<
      {
        FacetName: string;
        FacetValue: string;
      }[]
    >;
    selected: Observable<
      | {
          FacetName: string;
          FacetValue: string;
        }[]
      | null
    >;
  }[] = [];
  fetchFacetOptions$ = new Subject<string>();

  showMore = false;

  @Input() closeAll$ = new BehaviorSubject<boolean>(false);
  activeFacet: string | null = null;

  @Output() didSearch = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  constructor(
    private siteIdService: SiteIdService,
    private searchBarService: SearchBarService2,
    private router: Router,
    private overlayService: OverlayService,
    private searchService: SearchService
  ) {
    this.siteID = this.siteIdService.site.siteID;

    this.searchDriver = this.searchBarService.searchDriver;
    this.facetConfig = this.searchBarService.searchDriver.config;

    // facet states
    this.facetDataArr = this.searchService.setupFacets(
      this.facetConfig.facets,
      this.fetchFacetOptions$,
      this.searchDriver
    );

    // sub to overlay click
    this.overlayService.didClick
      .pipe(
        takeUntil(this.destroy$),
        filter(didClick => didClick)
      )
      .subscribe(() => {
        this.activeFacet = null;
        this.overlayService.hide();
      });
  }

  ngOnInit(): void {
    this.closeAll$
      .pipe(
        takeUntil(this.destroy$),
        filter(closeAll => closeAll)
      )
      .subscribe(() => {
        this.activeFacet = null;
        this.overlayService.hide();
      });
  }

  toggleOption(facetOption: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }) {
    const facet = {
      FacetName: facetOption.FacetName as FacetField,
      FacetValue: facetOption.FacetValue,
    };
    if (facetOption.selected) {
      this.searchDriver.removeFilter(facet);
    } else {
      this.searchDriver.addFilter(facet);
    }
  }

  clearFacets(fields: FacetField[]) {
    fields.forEach(field => this.searchDriver.clearFilter(field));
  }

  doSearch() {
    this.didSearch.emit();
    this.activeFacet = null;
    this.overlayService.hide();

    // get facets$
    if (this.router.url.startsWith('/search')) {
      this.searchBarService.searchDriver.doSyncFilters$.next(true);
    } else {
      const filters = this.searchDriver.facets$.getValue();
      const requestState = {
        ...defaultRequestState,
        ...configs2[this.siteID].initRequestState,
        filters,
      };
      const stateParams = this.searchService.transformRequestStateToQueryParams(
        requestState,
        this.searchDriver.config.facets.flatMap(facet =>
          facet.type === 'destination' ? facet.fields : facet.field
        )
      );

      this.router.navigate(['search'], {
        queryParams: stateParams,
      });
    }
  }

  shouldShowMore() {
    this.showMore = true;
  }

  toggleWideFacet(field: string) {
    if (this.activeFacet === field) {
      this.activeFacet = null;
      this.overlayService.hide();
    } else {
      // check should toggle showMore
      const matchIdx = this.facetConfig.facets.findIndex(facet => {
        switch (facet.type) {
          case 'destination':
            return facet.fields.some(
              facetConfigField => facetConfigField === field
            );
          default:
            return facet.field === field;
        }
      });
      if (matchIdx > -1) {
        if (matchIdx > 1) {
          this.showMore = true;
        }
        this.activeFacet = field;
        this.overlayService.show();
        this.fetchFacetOptions$.next(field);
      }
    }
  }

  toggleAccordionFacet(field: string) {
    if (this.activeFacet === field) {
      this.activeFacet = null;
    } else {
      this.activeFacet = field;
      this.fetchFacetOptions$.next(field);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
