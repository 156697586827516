import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {OverlayService} from '@app/services';
import {ChevronIcon, MailIcon, PhoneIcon} from '@app/icons';
import {AsyncPipe} from '@angular/common';
import {appShellNoRenderDirective} from '@directive/app-shell-no-render.directive';
import {SearchBar2Component} from '@app/search-bar-2/search-bar.component';

@Component({
  standalone: true,
  selector: 'action-bar',
  imports: [
    MailIcon,
    PhoneIcon,
    ChevronIcon,
    AsyncPipe,
    RouterLink,
    appShellNoRenderDirective,
    SearchBar2Component,
  ],
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.css'],
})
export class ActionBarComponent implements OnDestroy {
  activeMenuIdx: number | null = null;

  closeAccordionFacets$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private overlayService: OverlayService
  ) {
    // close on route change
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.closeMenu();
      });

    // sub to overlay click
    this.overlayService.didClick
      .pipe(
        filter(didClick => didClick),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.closeMenu();
      });
  }

  closeMenu() {
    this.activeMenuIdx = null;
    this.closeAccordionFacets$.next(true);
    this.overlayService.hide();
  }

  toggleMenu(event: MouseEvent, idx: number | null) {
    if (this.activeMenuIdx === idx) {
      this.closeMenu();
    } else {
      this.activeMenuIdx = idx;
      this.closeAccordionFacets$.next(true);
      this.overlayService.show();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
