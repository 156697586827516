import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  OnDestroy,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {
  SiteIdService,
  TripFinderHeaderService,
  ESignupService,
} from '@app/services';
import {Site} from '@app/models';
import {Router, NavigationEnd, RouterLink} from '@angular/router';
import {ChevronIcon, HamburgerIcon, MailIcon, PhoneIcon} from '@app/icons';

@Component({
  standalone: true,
  selector: 'app-navigation',
  imports: [MailIcon, ChevronIcon, PhoneIcon, HamburgerIcon, RouterLink],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnDestroy {
  @Input() alt = false;
  readonly Site = Site;
  siteID;
  phone;
  tel;
  mobileNavOpen = false;
  activeMenuIdx: number | null = null;
  outsideClick$ = fromEvent(this.document, 'click');
  mobileOutsideClickSub: Subscription | null = null;
  desktopOutsideClickSub: Subscription | null = null;

  private destroy$ = new Subject<void>();

  @ViewChild('root') root!: ElementRef;

  constructor(
    private siteIdService: SiteIdService,
    public router: Router,
    public tripFinderHeader: TripFinderHeaderService,
    private eSignup: ESignupService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.siteID = this.siteIdService.site.siteID;
    this.phone = this.siteIdService.site.phone;
    this.tel = this.phone.replace(/\D/g, '');

    // close mobile on route change
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.closeMobileMenu();
      });
  }

  openMobileMenu() {
    this.mobileNavOpen = true;
    if (this.mobileOutsideClickSub) {
      this.mobileOutsideClickSub.unsubscribe();
    }
    this.mobileOutsideClickSub = this.outsideClick$.subscribe(
      this.handleMobileOutsideClick.bind(this)
    );
  }

  closeMobileMenu() {
    this.mobileNavOpen = false;
    if (this.mobileOutsideClickSub) {
      this.mobileOutsideClickSub.unsubscribe();
    }
  }

  toggleMobileMenu() {
    if (this.mobileNavOpen) {
      this.closeMobileMenu();
    } else {
      this.openMobileMenu();
    }
  }

  handleMobileOutsideClick(event: Event) {
    const focusOut = !this.root.nativeElement.contains(event.target);
    if (focusOut) {
      this.closeMobileMenu();
    }
  }

  toggleMenu(event: Event, idx: number | null) {
    event.stopPropagation();
    this.activeMenuIdx = this.activeMenuIdx === idx ? null : idx;
    if (this.desktopOutsideClickSub) {
      this.desktopOutsideClickSub.unsubscribe();
    }
    if (this.activeMenuIdx !== null) {
      this.desktopOutsideClickSub = this.outsideClick$.subscribe(
        this.handleDesktopOutsideClick.bind(this)
      );
    }
  }

  handleDesktopOutsideClick(event: Event) {
    const focusOut = !this.root.nativeElement.contains(event.target);
    if (focusOut) {
      this.toggleMenu(event, null);
      if (this.mobileOutsideClickSub) {
        this.mobileOutsideClickSub.unsubscribe();
      }
    }
  }

  handleMobileEscape() {
    if (this.mobileNavOpen) {
      this.closeMobileMenu();
    }
  }

  handleEscape(event: Event) {
    if (this.activeMenuIdx !== null) {
      this.toggleMenu(event, null);
    }
  }

  navFocusOut(event: FocusEvent) {
    const focusOut = !this.root.nativeElement.contains(event.relatedTarget);
    if (focusOut) {
      this.activeMenuIdx = null;
    }
  }

  showEsignup() {
    this.eSignup.showModal();
  }

  ngOnDestroy() {
    this.mobileOutsideClickSub?.unsubscribe();
    this.desktopOutsideClickSub?.unsubscribe();
    this.destroy$.next();
  }
}
