<app-json-ld [jsonLD]="structuredData"></app-json-ld>
<div class="theme-{{site.siteID}} cls-prevention">
  <app-hero [hero]="hero" [showVid]="showVid" [siteID]="site.siteID"></app-hero>
  <div *appShellNoRender appInViewport (inViewport)="onVisibilityChanged($event)"
    class="relative z-30 hidden lg:block lg:-mt-20 lg:mb-11 width-container px-4">
    <div class="py-8 px-5 bg-primary-300 rounded shadow-lg">
       <search-bar-2></search-bar-2>
    </div>
  </div>
  @if (promotions$ | async; as promotions) {
    @if (promotions.length > 0) {
      <section *appShellNoRender class="text-center width-container min-h-[58.625rem] md:min-h-[52.9375rem]">
        <div class="py-8 px-5">
          <div class="flex flex-col items-center">
            <div class="mb-4 lg:mb-0 lg:px-4 lg:flex-1">
              <h1 class="opt-out text-lg sm:text-2xl font-bold text-primary-300 uppercase tracking-wide mb-2 font-theme-serif">
                Exclusive {{site.websiteName}} Deals & Promotions</h1>
              <div class="text-sm sm:text-base">Seize Unforgettable Moments: Exclusive Promotions for Adventurous Souls</div>
            </div>
          </div>
        </div>
        <promotion-slider [promotions]="promotions"></promotion-slider>
      </section>
    }
  }
  @defer() {
    <section>
      @switch (site.siteID) {
        @case ('Alaska') {
          <div class="w-full bg-alaska-grad pt-16 lg:pt-[88px] pb-14">
            @if (site.overview && site.overview.videoID) {
              <div class="width-container mb-12 lg:mb-16 md:px-[88px] text-center">
                <div class="text-white">
                  <h2
                    class="opt-out text-lg sm:text-xl md:text-2xl tracking-widest font-bold uppercase font-theme-serif mb-2">
                    Getting Started With {{site.websiteName}}</h2>
                  @if (site.overview.subtitle) {
                  <div class="text-sm md:text-base">{{site.overview.subtitle}}</div>
                  }
                  <div class="relative mb-6">
                    <app-youtube-player [videoID]="site.overview.videoID" [res]="site.overview.thumbnailRes"></app-youtube-player>
                  </div>
                </div>
              </div>
            }
            <div class="width-container text-white">
              <div class="lg:flex lg:justify-center">
                <div class="lg:max-w-[1146px] lg:flex lg:items-center md:px-[88px]">
                  <div class="relative">
                    <div class="hidden lg:block absolute left-0 -translate-x-2/3">
                      <img height='377' width='421' src="{{ 'homepage/Alaska/acs-alaska-pixel.png' | imgix:'fit=crop':'w=421':'q=100' }}" alt="Pixel drawing of Alaska state outline" class="max-w-none">
                    </div>
                    <div class="relative max-w-[28rem] lg:max-w-[15rem] mx-auto lg:ml-0 lg:mr-8 mb-12 lg:mb-0 px-11 lg:px-0">
                      <div class="mb-7 font-theme-serif">
                        <div class="mr-2 inline-block text-3xl font-semibold leading-[34px]">What is an</div>
                        <div class="inline-block text-4xl font-extrabold leading-[44px]">Alaskan Cruisetour?</div>
                      </div>
                      <div class="mb-4">The only way to see the Alaskan wilderness at its best:</div>
                      <div class="mb-7">expertly-crafted land trips before or after a cruise.</div>
                      <div class="text-center lg:text-left">
                        <a routerLink="/why-choose-an-alaska-cruise-tour" class="btn-grad">More info</a>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <div class="flex-1 max-w-[230px]">
                      <img height='344' width='230' src="{{ 'homepage/Alaska/cruise.png' | imgix:'fit=crop':'w=230':'q=100' }}"
                      alt="A cruise ship on the water in front of an illustrious mountain.  The word 'Cruise' is atop of the image and its borders shape like a wind-filled sail curving to the right" class="lg:mb-3">

                      <p class="opt-out hidden lg:block leading-6 w-3/4 text-sm font-light">Enjoy top-rated 7 day cruises of the glaciers and top alaskan ports of call.</p>
                    </div>
                    <div class="flex-1 max-w-[230px]">
                      <img height='344' width='230' src="{{ 'homepage/Alaska/train.png' | imgix:'fit=crop':'w=230':'q=100' }}"
                      alt="A side profile of a train with yellow border coming towards the camera.  The word 'Train' is atop of the image and its borders shape like a wind-filled sail curving to the right" class="lg:mb-3">

                      <p class="opt-out hidden lg:block leading-6 w-3/4 text-sm font-light">Enjoy scenic rail travel from the ship to the lodge via rail service for an all-encompassing trip.</p>
                    </div>
                    <div class="flex-1 max-w-[230px]">
                      <img height='344' width='230' src="{{ 'homepage/Alaska/lodge.png' | imgix:'fit=crop':'w=230':'q=100' }}"
                      alt="A log cabin sits in front a calm lake with an evergreen at the front porch.  The word 'Lodge' is atop of the image and its borders shape like a wind-filled sail curving to the right" class="lg:mb-3">

                      <p class="opt-out hidden lg:block leading-6 w-3/4 text-sm font-light">Spend 2+ nights on land at hotels located riverside near legendary national parks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        @default {
          <div class="w-full bg-light-gray">
            <div class="width-container md:w-full bg-light-gray mb-6 md:p-14 pt-8 text-center">
              @if (site.overview && site.overview.videoID) {
              <div class="p-8 pb-6">
                <h2
                  class="opt-out text-lg sm:text-xl md:text-2xl tracking-widest text-primary-300 font-bold uppercase font-theme-serif mb-2">
                  Getting Started With {{site.websiteName}}</h2>
                @if (site.overview.subtitle) {
                <div class="text-sm md:text-base">{{site.overview.subtitle}}</div>
                }
                <div class="relative mb-6">
                  <app-youtube-player [videoID]="site.overview.videoID" [res]="site.overview.thumbnailRes"></app-youtube-player>
                </div>
              </div>
              }
              <div class="relative p-8 pb-0 md:pb-8">
                <img height='144' width='604' src="{{ 'homepage/ornate_outline.svg' | imgix:'fit=crop':'h=144':'q=100' }}"
                  alt="" class="h-36 m-auto">
                <div class="absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-5/6 max-w-xl">
                  <h2
                    class="opt-out text-lg sm:text-xl md:text-2xl tracking-widest text-primary-300 font-bold uppercase font-theme-serif h-4 sm:h-full">
                    The {{site.cruiselineName ? site.cruiselineName + ' Insider' : site.websiteName}} Difference</h2>
                  <div class="hidden md:block mb-0 text-sm md:text-base">Experience Luxury with Expert Insight.</div>
                </div>
              </div>
              <app-tres-cards [cards]="site.insiderDifference"
                class="flex flex-col sm:flex-row flex-wrap justify-evenly items-center bg-light-gray"></app-tres-cards>
            </div>
          </div>
        }
      }
    </section>
    @switch (site.siteID) {
      @case ('Alaska') {
        <!-- TODO: CRUISELINE SLIDER -->
      }
      @default {
        <section *appShellNoRender class="text-center width-container mb-14">
          <destination-slider></destination-slider>
        </section>
      }
    }
    <section class="w-full bg-light-gray mb-14 md:mb-0 text-sm md:text-base md:p-6 md:pb-14 pt-0">
      <div class="text-center pt-14 p-8">
        <h2 class="opt-out text-lg sm:text-2xl font-bold text-primary-300 uppercase tracking-wide mb-2 font-theme-serif">
          What Our Customers Are Saying</h2>
        <div class="text-sm sm:text-base">We appreciate all of the feedback we receive and continuously strive to improve
          our services.</div>
      </div>
      <div *appShellNoRender class="width-container bg-off-white md:mb-2 md:px-12 lg:pt-12">
        <app-google-reviews class="pt-4"></app-google-reviews>
      </div>
    </section>
  }
</div>
