import {Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {NavigationService, SiteIdService} from '@app/services';
import {SearchBarService2} from './services/search-bar-service-2.service';
import {configs2} from './search/search.config';
import {SearchDriver} from './services/search-driver';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private navigationService: NavigationService,
    private searchBarService: SearchBarService2,
    private siteIdService: SiteIdService
  ) {}

  ngOnInit(): void {
    // setup navigationservice to start tracking nav changes
    this.navigationService.init();

    // setup site-wide searchDriver
    const config = {
      ...configs2[this.siteIdService.site.siteID].facetConfig,
    };
    this.searchBarService.searchDriver = new SearchDriver(config);
  }

  ngOnDestroy(): void {
    this.searchBarService.searchDriver.destroy();
  }
}
